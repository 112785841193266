@mixin spacer($gap: 10px) {
  &:not(:last-child) {
    margin-right: $gap;
  }
}

$screen-xs-min: 0px;
$screen-sm-min: 577px;
$screen-md-min: 769px;
$screen-lg-min: 993px;
$screen-xl-min: 1201px;

@mixin smonly {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin grid-generator($size, $grid-columns) {
  @for $i from 1 through $grid-columns {
    .col-#{$size}-#{$i} {
      width: percentage($i / $grid-columns);
    }
  }
}

@mixin respond-from($media) {
  @if $media == xs {
    @media (min-width: 0px) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $media == xl {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
