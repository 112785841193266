.header {
  @include lg {
    display: flex;
  }
  z-index: 3;

  @include lg {
    margin-top: 20px;
  }

  .search-box {
    margin-top: 20px;
  }

  .main-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include md {
      margin-bottom: 6px;
      width: 100%;
    }

    .search-area {
      position: relative;
      width: 100%;
      background-color: var(--primary);
      padding-bottom: 10px;

      .options {
        display: block;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        @include lg {
          padding: 20px;
        }
        @include lg {
          display: flex;
        }
        button {
          padding: 0;
          font-size: 24px;
        }
        .selectors {
          display: block;
          @include lg {
            flex-grow: 1;
            display: flex;
          }
          > div {
            margin: 5px;
          }
          .selectors-first {
            margin: 0;
            margin-bottom: 10px;
            justify-content: space-between;
            @include lg {
              > div {
                margin-right: 10px;
              }
            }
          }
          .selectors-second {
            margin: 0; 
            @include lg {
              flex-grow: 1;
             justify-content: space-between;
            }
          }
        }

        .add-user {
          display: flex;
          >* {
            margin-right: 20px;
          }
          flex-grow: 1;
          @include lg {
            >* {
              margin-right: auto;
            }
            display: block !important;
            
          }
          > * {
            display: block;
            margin-bottom: 10px;
          }

          @include md {
            display: flex !important;
            > * {
              margin-right: 10px;
              margin-bottom: unset;
            }
          }
        }

        > button {
          color: var(--alternateText);
          border: none;
          background: none;
          font-size: 12px;
          font-weight: 500;

          i {
            margin-left: 10px;
          }

          &.checkbox {
            display: flex;
            align-items: center;

            i {
              font-size: 16px;
            }
          }
        }
      }

      .customer-names {
        padding: 0 20px;
      }

      .routes {
        padding: 10px 30px;

        .route {
          justify-content: center;
          flex-wrap: wrap;
          display: flex;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }

        .destinations,
        .trip-dates {
          margin-bottom: 10px;
          min-height: 51px;
          position: relative;
          display: flex;
          align-items: center;
          border: 1px solid var(--primaryText);
          border-radius: 20px;
          padding: 10px 20px;
          @include smonly {
            margin-bottom: 10px;
          }

          .day,
          .month {
            color: var(--primaryText) !important;
          }
        }

        .destinations {
          flex-grow: 1;

          .to,
          .from {
            width: 50%;
          }

          p,
          i {
            color: var(--primaryText);
          }

          .placeholder {
            color: var(--secondaryTextLighten);
            font-size: 23px;
            line-height: 30px;
          }

          .city-name {
            font-size: 23px;
            line-height: 17px;
            margin-bottom: 5px;
          }

          .airport {
            font-size: 13px;
          }
        }

        .trip-dates {
          justify-content: space-around;
          margin-left: 20px;
          color: var(--primaryText);
          font-size: 17px;
          //&:active {
          //  background-color: darken(var(--primary), 10%);
          //}
        }

        .round-trip {
          font-size: 20px;
          margin: 0 20px;
          display: flex;
          align-items: center;
        }

        .to,
        .from,
        .round-trip {
          cursor: pointer;
        }
      }

      .search-footer {
        .add-route-button {
          color: var(--alternateText);
          font-size: 12px;
          background: none;
          border: none;
        }

        .unshrink-button {
          font-size: 20px;
          background-color: transparent;
          border: none;
          color: var(--primaryText);
          margin-top: -26px;
        }
      }

      &.is-shrinked {
        padding-bottom: 0;

        .routes {
          padding: 10px 30px;
        }

        .customer-names {
          margin-top: 10px;
        }

        .options {
          display: none;
        }

        .route-delete {
          display: none;
        }

        .trip-dates,
        .destinations {
          padding: 5px 20px;
        }
      }
    }

    .band {
      box-shadow: -6px 6px 0px 0px var(--headerShadow);
      height: 72px;
      display: flex;
      justify-content: space-between;
      background: linear-gradient(
        160deg,
        var(--secondary) 0%,
        var(--secondary) 60%,
        var(--primary) 60%,
        var(--primary) 100%
      );

      .brand {
        max-width: 30%;
        float: left;
        width: 160px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .title {
        max-width: 40%;
        position: relative;
        height: 100%;
        padding: 10px;
      }
    }
  }

  .right-side {
    width: 100%;
    @include md {
      width: calc(100% / 3);
    }
  }

  .menu {
    position: relative;
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: var(--primaryBg);
    padding: 0 10px;
    overflow-x: scroll;
    @include md {
      display: flex;
      padding: 0 5px 0 10px;
      overflow-x: unset;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-top: 20px;
    }

    .theme-switch {
      margin-left: 15px;

      .react-toggle-thumb {
        background-color: var(--secondary) !important;
      }
    }

    .links {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
      align-items: center;
      > * {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: var(--primaryText);
        font-size: 20px;
      }
    }

    .user {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: flex-end;
      align-items: center;
      color: var(--primaryText);
      font-size: 10px;
      padding-right: 10px;

      i {
        margin-right: 5px;
      }

      .logout {
        color: var(--primaryText);
        border: none;
        background-color: transparent;
        cursor: pointer;
        margin-left: 5px;
        font-size: 20px;
      }
    }
  }
}

.company-logo-changer {
  position: relative;
  height: 100%;
  .company-logo {
    display: flex;
    align-items: center;
    height: 100%;
    svg {
      margin-left: 10px;
    }
    img {
      width: 100%;
      max-width: 160px;
      height: auto;
    }
    * {
      color: var(--primaryText);
      font-size: 25px;
    }
  }

  .company-list {
    z-index: 2;
    position: absolute;
    left: 0;
    min-width: 100%;
    background-color: var(--primary);
    border: 1px solid var(--primaryText);
    > div {
      cursor: pointer;
      padding: 10px;
      color: var(--primaryText);
      &:hover {
        background-color: var(--secondary);
      }
    }
  }
}

.mobile-menu-toggle {
  color: var(--primaryText);
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 20px;
}

.right-side {
  &.is-mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 72px;
    z-index: 20000;
    background-color: var(--primaryBg);
    padding: 10px;
    margin-left: -100vw;
    transition: margin-left 300ms ease-in;
    &.is-open {
      margin-left: 0;
    }
    .exchange-rate {
      position: unset;
      margin: 0;
    }
    .mobile-menu {
      margin-left: 0;
      .user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        p,
        a {
          margin-right: 10px;
          color: var(--primaryText);
        }
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .link-title,
      .main-link {
        font-size: 18px;
        color: var(--primaryText);
        margin-bottom: 15px;
        text-decoration: none;
        font-weight: 700;
        svg {
          width: 40px;
        }
      }
      .main-link,
      .sub-link {
        &:hover {
          color: var(--primary);
        }
      }
      .has-sub-links {
        margin-bottom: 15px;
        ul {
          margin-top: 10px;
          li {
            margin-bottom: 5px;
            * {
              text-decoration: none;
            }
          }
        }
      }
      .sub-link {
        margin-left: 30px;
        color: var(--primaryText);
        margin-bottom: 5px;
        svg {
          width: 20px;
        }
      }
    }
  }
}
